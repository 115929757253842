import React, { useEffect, useState, useRef, memo } from 'react';

function TradingViewWidget() {
  const container = useRef();
  const hasRendered = useRef(false);
  const [hasIframeLoaded, setHasIframeLoaded] = useState(false);

  const handleClick = () => {
    window.location.href = "https://jasonmark.dev";
  };

  useEffect(
    () => {
      if (!hasRendered.current) {
        hasRendered.current = true;
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
          {
            "autosize": true,
            "symbol": "NASDAQ:AAPL",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "dark",
            "style": "1",
            "locale": "en",
            "enable_publishing": false,
            "allow_symbol_change": true,
            "watchlist": [
              "NASDAQ:MSFT",
              "NASDAQ:META",
              "NASDAQ:NFLX",
              "NASDAQ:GOOG",
              "NASDAQ:AMZN",
              "NASDAQ:NVDA",
              "NASDAQ:AAPL",
              "NASDAQ:TSLA"
            ],
            "details": true,
            "hotlist": true,
            "calendar": false,
            "support_host": "https://www.tradingview.com"
          }`;
        container.current.appendChild(script);
        waitForElement("iframe").then((iframe) => {
          if (iframe) {
            iframe.style.minHeight = "100vh";
            iframe.addEventListener("load", () => {
              setHasIframeLoaded(true);
            });
          }
        });
      }
    },
    [hasRendered]
  );

  console.log('!! hasIframeLoaded', hasIframeLoaded)
  return (
    <>
      <div className="tradingview-widget" ref={container} style={{ height: "100%", width: "100%" }}>
        <div className="tradingview-widget__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      </div>
      {hasIframeLoaded && <img onClick={handleClick}  src={"/jason-mark-logo.svg"} alt="logo" className="jason-mark-logo" />}
    </>

  );
}

function waitForElement(selector: string): Promise<Element | null> {
  return new Promise((resolve) => {
    // Check if the element already exists in the DOM.
    const element = document.querySelector(selector);
    if (element) {
      resolve(element);
      return;
    }

    // Create a MutationObserver to listen for changes in the DOM.
    const observer = new MutationObserver((mutations, observer) => {
      // Check if the element is now available in the DOM.
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
        // Once the element is found, disconnect the observer.
        observer.disconnect();
      }
    });

    // Start observing the document body for child list changes.
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export default memo(TradingViewWidget);
